$small: 300px;
$medium: 1000px;
$large: 1200px;

:root {
  --western-button-background-color: #2c191c;
  --western-button-accent-color: #f58b77;
  --western-button-accent-color-hover: #f15a29;
  --western-button-dashed-border: 1px dashed white;
  --western-button-circle-background: url("../../assets/images/right.png");
}

.menuBackground {
  @media screen and (min-width: $medium) {
    width: 100%;
    height: 30px;
    background-color: var(--western-button-background-color);
    height: 58px;
    border-bottom: solid 1px #fff;
  }
}

.menuDesktop {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;

  @media screen and (max-width: $medium) {
    display: none;
  }
}

.paginaInicial {
  white-space: nowrap;
  position: absolute;
  top: 0px;
  right: 820px;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  margin-right: 0px;
  background-color: var(--western-button-background-color);
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.9;

  @media screen and (max-width: $medium) {
    width: 100%;
    font-size: 30px;
    right: 0px;
    top: 0px;
    border-top: solid 1px #fff;
    border-bottom: solid 1px #fff;
    padding: 0px 0px;
  }
  .title {
    font-size: 20px;
    padding: 7px;
  }
  .value {
    margin-top: -20px;
    margin-left: 10px;
  }

  @media (min-width: $medium) and (max-width: $large) {
    .title {
      font-size: 16px;
    }
    right: 836px;
  }
}

.profileItemMenu {
  position: absolute;
  top: 0px;
  right: 680px;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  margin-right: 0px;
  background-color: var(--western-button-background-color);
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  opacity: 0.9;
  @media screen and (max-width: $medium) {
    width: 100%;
    font-size: 30px;
    right: 0px;
    top: 0px;
    border-top: solid 1px #fff;
    border-bottom: solid 1px #fff;
    padding: 0px 0px;
  }
  .title {
    font-size: 20px;
    padding: 7px;
  }
  .value {
    margin-top: -20px;
    margin-left: 10px;
  }

  @media (min-width: $medium) and (max-width: $large) {
    .title {
      font-size: 16px;
    }
    right: 738px;
  }
}

.depositMoney {
  position: absolute;
  top: 0px;
  right: 690px;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  margin-right: 0px;
  background-color: var(--western-button-background-color);
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  opacity: 0.9;
  @media screen and (max-width: $medium) {
    width: 100%;
    font-size: 30px;
    right: 0px;
    top: 37px;
    border-top: solid 1px #fff;
    border-bottom: solid 1px #fff;
    padding: 0px 0px;
  }
  .title {
    font-size: 20px;
    padding: 7px;
  }
  .value {
    margin-top: -20px;
    margin-left: 10px;
  }

  @media (min-width: $medium) and (max-width: $large) {
    .title {
      font-size: 16px;
    }
    right: 605px;
  }
}

.withdrawMoney {
  position: absolute;
  top: 0px;
  right: 576px;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  margin-right: 0px;
  background-color: var(--western-button-background-color);
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  opacity: 0.9;
  @media screen and (max-width: $medium) {
    width: 100%;
    font-size: 30px;
    right: 0px;
    top: 72px;
    border-top: solid 1px #fff;
    border-bottom: solid 1px #fff;
    padding: 0px 0px;
  }
  .title {
    font-size: 20px;
    padding: 7px;
  }
  .value {
    margin-top: -10px;
    margin-left: 10px;
  }

  @media (min-width: $medium) and (max-width: $large) {
    .title {
      font-size: 16px;
    }
    right: 506px;
  }
}

.rankingItemMenu {
  position: absolute;
  top: 0px;
  right: 500px;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  margin-right: 0px;
  background-color: var(--western-button-background-color);
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  opacity: 0.9;
  @media screen and (max-width: $medium) {
    width: 100%;
    font-size: 30px;
    right: 0px;
    top: 109px;
    border-top: solid 1px #fff;
    border-bottom: solid 1px #fff;
    padding: 0px 0px;
  }
  .title {
    font-size: 20px;
    padding: 7px;
  }
  .value {
    margin-top: -10px;
    margin-left: 10px;
    font-size: 30px;
  }

  @media (min-width: $medium) and (max-width: $large) {
    .title {
      font-size: 16px;
    }

    right: 390px;
  }
}

.scoreItemMenu {
  position: absolute;
  top: 0px;
  right: 270px;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  margin-right: 0px;
  background-color: var(--western-button-background-color);
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  opacity: 0.9;
  @media screen and (max-width: $medium) {
    width: 100%;
    font-size: 30px;
    right: 0px;
    top: 146px;
    border-top: solid 1px #fff;
    border-bottom: solid 1px #fff;
    padding: 0px 0px;
  }
  .title {
    font-size: 20px;
    padding: 7px;
  }
  .value {
    margin-top: -10px;
    margin-left: 10px;
    font-size: 30px;
  }

  @media (min-width: $medium) and (max-width: $large) {
    .title {
      font-size: 16px;
    }

    right: 200px;
    z-index: 10;
  }
}

.moneyItemMenu {
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  margin-right: 0px;
  background-color: var(--western-button-background-color);
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  opacity: 0.9;
  @media screen and (max-width: $medium) {
    width: 100%;
    font-size: 30px;
    right: 0px;
    top: 183px;
    border-top: solid 1px #fff;
    border-bottom: solid 1px #fff;
    padding: 0px 0px;
  }
  .title {
    font-size: 20px;
    padding: 7px;
  }
  .value {
    margin-top: -10px;
    margin-left: 10px;
    font-size: 30px;
  }

  @media (min-width: $medium) and (max-width: $large) {
    .title {
      font-size: 16px;
    }
  }
}

@keyframes animatedBackground {
  10% {
    background-size: 100%;
  }
  40% {
    background-size: 105%;
    background-position: top center;
  }
  100% {
    background-size: 100%;
  }
}
