$small: 300px;
$medium: 1000px;
:root {
  --western-button-background-color: #2c191c;
  --western-button-accent-color: #f58b77;
  --western-button-accent-color-hover: #f15a29;
  --western-button-dashed-border: 1px dashed white;
  --western-button-circle-background: url("../../assets/images/right.png");
  --western-button-circle-background-user: url("../../assets/images/user-cowboy.png");
}

.scoreboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  .star {
    position: absolute;
    height: 90px;
    z-index: 1;
    @media screen and (max-width: $medium) {
      height: 60px;
    }

    @media screen and (min-width: $medium) {
      &.opponent {
        animation: star 1.5s linear alternate infinite;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
      }
      &.you {
        animation: star2 1.5s linear alternate infinite;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
      }
    }

    @media screen and (max-width: $medium) {
      &.opponent {
        animation: starMobile 1.5s linear alternate infinite;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
      }
      &.you {
        animation: star2Mobile 1.5s linear alternate infinite;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
      }
    }
  }
  .trucoMessage {
    background-color: #2c191c;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 200;
    font-weight: bold;
    font-size: 40px;
    position: absolute;
    color: #fff;
    width: calc(100% - 291px);
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    &.hidden {
      display: none;
    }
    @media screen and (max-width: $medium) {
      width: 100%;
      font-size: 25px;
      .tape {
        margin-top: 250px;
      }
    }
    left: 0;
    .tape {
      background-color: var(--western-button-background-color);
      border-top: solid 1px #fff;
      border-bottom: solid 1px #fff;
      width: 100%;
      span {
        border-top: var(--western-button-dashed-border);
        border-bottom: var(--western-button-dashed-border);
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 10px;
        padding: 5px 0px;
        .truco {
          animation: scale 1.5s linear alternate infinite;
          transform: scale(1);
          transition: all 700ms;
          position: absolute;
          margin-top: -140px;
        }
        .buttons {
          display: flex;
          align-items: center;
          justify-content: center;
          button {
            margin: 0px 8px;
            background-color: transparent;
            color: #fff;
            min-width: 100px;
            border-color: var(--western-button-accent-color-hover);
            height: 40px;
            cursor: pointer;
            &:first-of-type {
              margin-left: 0px;
            }
            &:last-of-type {
              margin-right: 0px;
            }
            &:hover {
              background-color: var(--western-button-accent-color-hover);
            }
          }
          button[disabled] {
            border-color: black;
            color: white;
            cursor: not-allowed;
          }
        }
      }
    }
  }

  @keyframes scale {
    0% {
      scale: 0.7;
    }

    100% {
      scale: 1;
    }
  }

  @keyframes star {
    0% {
      top: calc(100vh - 90px - 20px);
      left: 20px;
      transform: rotate(0deg);
    }

    100% {
      top: 10px;
      left: calc(100vw - 291px - 90px - 20px);
      transform: rotate(360deg);
    }
  }

  @keyframes star2 {
    0% {
      top: 20px;
      left: calc(100vw - 291px - 90px - 20px);
      transform: rotate(0deg);
    }

    100% {
      top: calc(100vh - 90px - 20px);
      left: 20px;
      transform: rotate(360deg);
    }
  }

  @keyframes starMobile {
    0% {
      top: calc(100vh - 60px);
      left: 0px;
      transform: rotate(360deg);
    }

    100% {
      top: 34px;
      left: calc(100vw - 60px);
      transform: rotate(0deg);
    }
  }

  @keyframes star2Mobile {
    0% {
      top: 34px;
      left: calc(100vw - 60px);
      transform: rotate(0deg);
    }

    100% {
      top: calc(100vh - 60px);
      left: 0px;
      transform: rotate(360deg);
    }
  }

  header {
    z-index: 2;
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    color: #fff;
    padding: 7px 10px;
    box-sizing: border-box;
    background-color: var(--western-button-background-color);
    @media screen and (min-width: $medium) {
      width: calc(100vw - 335px);
      margin-left: auto;
      display: none;
    }
    .current {
      color: var(--western-button-accent-color-hover);
    }
    div {
      align-items: center;
      font-family: "Rye", cursive;
      font-size: 11px;
      display: flex;
      flex-direction: row;
      ul {
        font-size: 14px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: 0px;
        padding: 0px;
        &:first-of-type {
          margin-left: 8px;
          .points {
            margin-left: 10px;
            margin-right: 5px;
          }
        }
        &:last-of-type {
          margin-right: 8px;
          .points {
            margin-right: 10px;
            margin-left: 5px;
          }
        }
        li {
          list-style: none;
          height: 10px;
          width: 10px;
          border-radius: 30px;
          margin: 0px 2px;
          background-color: #fff;
          &.points {
            width: auto;
            height: auto;
            background-color: transparent;
            font-weight: bold;
          }
          &.win {
            background-color: var(--western-button-accent-color-hover);
          }
        }
      }
    }
  }

  nav.mobile-menu {
    position: absolute;
    right: 0;
    height: 100%;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    ul {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      li {
        list-style: none;
        width: 70px;
        margin-left: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--western-button-background-color);
        color: #fff;
        padding: 10px 15px;
        cursor: pointer;
        margin-top: 10px;
        border: double 4px #fff;
        border-right: 0px;
        border-radius: 3px;
      }
    }
    @media screen and (min-width: $medium) {
      display: none;
    }
  }
  .menu-aside {
    @media screen and (max-width: $medium) {
      display: none;
    }
    --color-font: #421313;
    background-image: url("../../assets/images/background.jpg");
    background-size: 100% 100%;
    background-repeat: repeat-y;
    min-height: 100vh;
    margin: 0px;
    padding: 0px;
    border-radius: 0px;

    .button-group {
      display: flex;
      flex-direction: column;
    }

    ul {
      padding-inline-start: 0px;
    }

    li {
      list-style: none;
    }

    .infos-game {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 30px;
      img {
        width: 200px;
        border-radius: 15px;
      }

      color: var(--color-font);
    }

    .infos-user {
      margin-top: 16px;
      display: flex;

      .user {
        display: grid;
        grid-template-rows: 1fr 1fr 1fr;
        grid-template-rows: 1fr 40px 40px;
        margin: 0px;

        li {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 10px;
          font-size: 14px;
          margin-bottom: 3px;
          color: var(--color-font);
        }

        li:first-child {
          margin-bottom: 10px;
          font-size: 18px;
          background-color: var(--western-button-background-color);
          background-image: repeating-linear-gradient(
              45deg,
              rgba(0, 0, 0, 0.3),
              rgba(0, 0, 0, 0.3) 10%,
              transparent 0%,
              transparent 20%
            ),
            repeating-linear-gradient(
              -45deg,
              rgba(0, 0, 0, 0.3),
              rgba(0, 0, 0, 0.3) 10%,
              transparent 0%,
              transparent 20%
            );
          background-position: center center;
          color: #fff;
          margin: 2px;
          border-radius: 3px;
        }
        li:last-child {
          margin-bottom: 10px;
        }
      }
    }
  }
}

.panel {
  max-width: 600px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  background-size: 100%;
  background: rgba(0, 0, 0, 0.3);
  background-image: url(../../assets/images/madeira2.jpg);
  background-size: 100% 100%;
  min-height: 100vh;
  margin-top: 0px;
  display: flex;
  flex-direction: column;

  .logo {
    width: 170px;
    margin-top: 0px;
  }

  .login-logout {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 15px;
    text-align: center;
    a {
      color: #fff;
      z-index: 1;
      width: 400px;
      margin-top: -20px;
      padding-top: 15px;
      padding-bottom: 15px;
      &:hover {
        cursor: pointer;
        color: #ccc;
      }
    }
  }

  table {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.4);
    background-image: repeating-linear-gradient(
        45deg,
        rgba(0, 0, 0, 0.3),
        rgba(0, 0, 0, 0.3) 10%,
        transparent 0%,
        transparent 20%
      ),
      repeating-linear-gradient(
        -45deg,
        rgba(0, 0, 0, 0.3),
        rgba(0, 0, 0, 0.3) 10%,
        transparent 0%,
        transparent 20%
      );
    padding: 20px 30px;
    border-radius: 3px;
    font-size: 22px;
  }

  .western-button-wrapper {
    margin-top: 5px;
  }

  img {
    width: 200px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    margin-bottom: 30px;
    padding: 20px;
    background-color: #fff;
  }

  .span {
    position: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width: $medium) {
    margin-top: 0px;
    padding-bottom: 90px;
  }
}

.background {
  background-size: 100%;
  background-image: url(../../assets/images/old-west.jpg) !important;
  animation: animatedBackground 20s linear infinite alternate;

  @keyframes animatedBackground {
    10% {
      background-size: 100%;
    }
    40% {
      background-size: 105%;
      background-position: top center;
    }
    100% {
      background-size: 100%;
    }
  }
}

.audioPlayer {
  position: absolute;
  right: 0;
  top: 50px;
  right: 10px;

  @media screen and (max-width: $medium) {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    margin-top: 160px;
  }
}

@import url("https://fonts.googleapis.com/css?family=Rye");

.peopleInQeue {
  position: absolute;
  font-family: ui-monospace;
  top: 42px;
  right: 4px;
  color: black;
  font-size: 30px;
  z-index: 2;
}

.western-button-wrapper-user {
  cursor: pointer;
  scale: 0.7;
  background-color: var(--western-button-background-color);
  &.disabled {
    cursor: not-allowed;
    background-color: rgb(90, 47, 41);
    opacity: 0.7;
    .western-button {
      cursor: not-allowed;
      &::after {
        display: none;
      }
    }
    &::after {
      display: none;
    }
    .western-button-container {
      &::after {
        border: 0px;
      }
    }
  }
  padding: 5px;
  position: relative;
  border-radius: 5px;
  margin-left: -15px;
  max-width: 300px;

  .western-button-user {
    display: block;
    text-decoration: none;
    text-align: center;
    font-family: "Rye", cursive;
    font-size: 18px;
    color: white;
    width: 100%;
    min-width: 280px;
    height: 84px;
    line-height: 70px;
    padding: 0 30px 0 0;
    border-top: var(--western-button-dashed-border);
    border-bottom: var(--western-button-dashed-border);

    &::after {
      content: "";
      display: block;
      height: 70px;
      width: 70px;
      border: 1px solid var(--western-button-background-color);
      border-radius: 45px;
      background-color: var(--western-button-accent-color);
      position: absolute;
      top: 24px;
      right: -35px;
      transition: all 300ms ease-in;
    }
  }

  &::after {
    content: "";
    display: block;
    background-image: var(--western-button-circle-background-user);
    background-position: 23px 15px;
    background-size: 40%;
    background-repeat: no-repeat;
    height: 50px;
    width: 50px;
    border: 1px solid var(--western-button-background-color);
    border-radius: 45px;
    position: absolute;
    top: 34px;
    right: -25px;
  }

  &:hover .western-button-user::after {
    background-color: var(--western-button-accent-color-hover);
  }

  .western-button-container-user {
    border: solid 1px #fff;
    padding: 7px 7px;
    button {
      background: transparent;
      cursor: pointer;
      border-top: var(--western-button-dashed-border);
      border-bottom: var(--western-button-dashed-border);
      margin-top: 5px;
      margin-bottom: 5px;
      border-left: 0px;
      border-right: 0px;
    }
    &::after {
      content: "";
      display: block;
      height: 56px;
      width: 56px;
      border: 2px solid var(--western-button-background-color);
      border-radius: 45px;
      position: absolute;
      top: 30px;
      right: -29px;
    }
  }
}

.western-button-wrapper {
  cursor: pointer;
  scale: 0.7;
  background-color: var(--western-button-background-color);
  &.disabled {
    cursor: not-allowed;
    background-color: rgb(90, 47, 41);
    opacity: 0.7;
    .western-button {
      cursor: not-allowed;
      &::after {
        display: none;
      }
    }
    &::after {
      display: none;
    }
    .western-button-container {
      &::after {
        border: 0px;
      }
    }
  }
  padding: 5px;
  position: relative;
  border-radius: 5px;
  margin-left: -15px;
  max-width: 300px;

  .western-button {
    display: block;
    text-decoration: none;
    text-align: center;
    font-family: "Rye", cursive;
    font-size: 22px;
    color: white;
    width: 100%;
    min-width: 280px;
    height: 84px;
    line-height: 70px;
    padding: 0 30px 0 0;
    border-top: var(--western-button-dashed-border);
    border-bottom: var(--western-button-dashed-border);

    &::after {
      content: "";
      display: block;
      height: 70px;
      width: 70px;
      border: 1px solid var(--western-button-background-color);
      border-radius: 45px;
      background-color: var(--western-button-accent-color);
      position: absolute;
      top: 24px;
      right: -35px;
      transition: all 300ms ease-in;
    }
  }

  &::after {
    content: "";
    display: block;
    background-image: var(--western-button-circle-background);
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: center;
    height: 50px;
    width: 50px;
    border: 1px solid var(--western-button-background-color);
    border-radius: 45px;
    position: absolute;
    top: 34px;
    right: -25px;
  }

  &:hover .western-button::after {
    background-color: var-user(--western-button-accent-color-hover);
  }

  .western-button-container {
    border: solid 1px #fff;
    padding: 7px 7px;
    button {
      background: transparent;
      cursor: pointer;
      border-top: var(--western-button-dashed-border);
      border-bottom: var(--western-button-dashed-border);
      margin-top: 5px;
      margin-bottom: 5px;
      border-left: 0px;
      border-right: 0px;
    }
    &::after {
      content: "";
      display: block;
      height: 56px;
      width: 56px;
      border: 2px solid var(--western-button-background-color);
      border-radius: 45px;
      position: absolute;
      top: 30px;
      right: -29px;
    }
  }
}

.topLeftButtonNotification {
  content: "";
  display: block;
  height: 56px;
  width: 56px;
  border: 2px solid var(--western-button-background-color);
  border-radius: 45px;
  position: absolute;
  top: 0px;
  left: 0px;
}

.western-timer-wrapper {
  cursor: pointer;
  scale: 0.8;
  background-color: var(--western-button-background-color);
  padding: 5px;
  position: relative;
  border-radius: 5px;
  margin-left: -15px;
  max-width: 300px;

  .western-timer {
    display: block;
    text-decoration: none;
    text-align: center;
    font-family: "Rye", cursive;
    font-size: 28px;
    color: white;
    width: 100%;
    min-width: 235px;
    height: 84px;
    line-height: 70px;
    padding: 0 0px 0 0;
    border-top: var(--western-button-dashed-border);
    border-bottom: var(--western-button-dashed-border);
  }

  &:hover .western-timer::after {
    background-color: var(--western-button-accent-color-hover);
  }

  .western-timer-container {
    border: solid 1px #fff;
    padding: 7px 7px;
    button {
      background: transparent;
      cursor: pointer;
      border-top: var(--western-button-dashed-border);
      border-bottom: var(--western-button-dashed-border);
      margin-top: 5px;
      margin-bottom: 5px;
      border-left: 0px;
      border-right: 0px;
    }
  }
}
